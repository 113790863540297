.studentsWrap {
  padding-top: 210px;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  flex-direction: column;

  .warning-container {
    max-width: 600px;
    margin: 24px;
  }
}

.actionsWrap {
  display: flex;
  padding: 24px;
  border-radius: 6px;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
  width: 100%;
  max-width: 600px;
  background: #fff;
}

.input {
  font-family: Raleway, sans-serif;
  line-height: 26px;
  font-weight: 500;
  font-size: 24px;
  width: 100%;
  border: solid 1px #dcdcdc;
  transition: box-shadow 0.3s, border 0.3s;
  padding: 10px;
  color: #777;
  background-color: rgba(0, 0, 0, .05);
  margin-right: 20px;
}

.input:focus {
  color: #323436;
  background-color: rgba(150, 150, 150, .05);
  outline: 0;
  border: solid 1px #707070;
  box-shadow: 0 0 5px 1px #969696;
}

.studentButton {
  background-color: #1a1a1a;
  font-family: Raleway, sans-serif;
  cursor: pointer;
  box-sizing: border-box;
  color: #bbbaba;
  text-align: inherit;
  line-height: 17px;
  font-weight: 500;
  font-size: 24px;
  padding: 15px 20px;
  border: none;
}

.studentButton:hover {
  background-color: #3498db;
  color: #fff;
}

.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  padding-top: 17px;
  color: #d1d1d1;
  transition: color 0.2s;
  border-top: 1px solid #333;
  padding-left: 1.5em;
  min-height: 36px;
}

.bm-item:hover {
  color: white;
}

.bm-item:hover:after {
  display: none;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 50px;
  top: 36px;
}

.bm-burger-bars {
  background: #252525;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: #252525;
  padding: 2.5em 0 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  left: 0;
}

@media (max-width: 620px) {
  .actionsWrap {
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    margin-left: 20px;
  }
  .input {
    margin-bottom: 20px;
    margin-right: 0;
  }
}

@media (max-width: 620px) {
  .wrap {
    padding-top: 120px;
  }
}