.footerContainer {
  width: 100%;
  height: 56px;
  background: #292929;
}

.footerWrap {
  display: flex;
  justify-content: center;
  height: 56px;
}

.copyright {
  font-family: Raleway,sans-serif;
  line-height: 26px;
  font-weight: 500;
  color: rgba(255,255,255,.4);
  font-size: 12px;
  margin: auto 0;
}

.footerLink {
  font-size: 12px;
  color: #aaa;
  border-bottom: 1px solid rgba(255,255,255,.07);
  padding-bottom: 2px;
  margin: 15px 2px 18px;
  font-family: Raleway,sans-serif;
  line-height: 26px;
  font-weight: 500;
  transition: color .3s;
}

.footerLink:hover {
  color: #ed6162;
  transition: color .3s;
}

.between {
  font-size: 12px;
  color: #aaa;
  margin: auto 2px 18px;
  padding-bottom: 2px;
}

@media (max-width: 420px) {
  .footerWrap {
    display: block;
  }
  .copyright {
    margin-left: calc(50% - 60px);
  }
  .mobile {
    margin-left: calc(50% - 143px);
  }
}