.size-sm {
  padding: 10px
}

.size-md {
  padding: 12px 24px
}

.size-lg {
  padding: 20px 54px
}