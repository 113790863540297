.studentWrap {
  padding: 100px 50px 0;
  width: 100%;
  max-width: 918px;
  margin: 0 auto;
}

#teacher-assistance-student,
#teacher-assistance-teacher {
  background-color: transparent;
  min-height: 150px;
  padding: 20px 10px 10px;
  border-radius: 10px;
  margin: 20px auto;
}
#teacher-assistance-student {
  margin-bottom: 50px;
  text-align: center;
  width: 600px;
  max-width: 100%;
}

#teacher-assistance-student .assist-me {
  background: linear-gradient(to bottom, #008cc8, #015e93);
  border: 3px solid #fff;
  border-radius: 100%;
  box-shadow: 0 -2px 0 3px #015e93 inset, 0 5px 5px rgba(0, 0, 0, 0.15),
  0 15px rgba(255, 255, 255, 0.25) inset;
  cursor: pointer;
  display: inline-block;
  height: 180px;
  width: 180px;
  line-height: 55px;
  font-size: 29px;
  color: #fff;
  text-align: center;
  text-transform: none;
  box-sizing: content-box;
  margin: 12px 12px 15px;
  padding: 20px;
}

.assist-me:hover {
  background: linear-gradient(45deg, #045c90, #045c90)!important;
  box-shadow: 0 -2px 0 3px #015e93 inset, 0 5px 5px rgba(1, 106, 161, 0.17),
  0 15px rgba(255, 255, 255, 0.32) inset!important;
}

#teacher-assistance-student .im-good {
  background: #e53030;
  border: 3px solid #fff;
  border-radius: 100%;
  box-shadow: 0 -2px 0 3px #c91919 inset, 0 5px 5px rgba(65, 8, 8, 0.17),
  0 15px rgba(255, 255, 255, 0.25) inset;
  cursor: pointer;
  display: inline-block;
  height: 80px;
  width: 80px;
  line-height: 25px;
  font-size: 21px;
  color: #fff;
  text-align: center;
  text-transform: none;
  box-sizing: content-box;
  margin: 12px;
  margin-bottom: 15px;
  padding: 20px;
}

#teacher-assistance-student .im-good:hover {
  background: #b60909;
  box-shadow: 0 -2px 0 3px #e53030 inset, 0 5px 5px rgba(0, 0, 0, 0.17),
  0 15px rgba(255, 255, 255, 0.32) inset;
}

#teacher-assistance-student input#student-comment {
  margin: 0 auto;
  display: block;
}
#teacher-assistance-student input#student-comment,
#teacher-assistance-student input#student-comment:focus {
  background-color: rgba(255, 255, 255, 0.8);
}
#teacher-assistance-student input {
  font-size: 24px;
  width: 100%;
  margin-right: 20px;
}
input[type='text'],
input[type='password'] {
  padding: 10px !important;
  padding: 10px;
  border: solid 1px #dcdcdc;
  transition: box-shadow 0.3s, border 0.3s;
}

input[type='text']:focus {
  color: #323436;
  background-color: rgba(150, 150, 150, 0.05);
  outline: 0;
}

[type='text']:focus,
[type='text'].focus {
  border: solid 1px #707070;
  box-shadow: 0 0 5px 1px #969696;
}

@media (max-width: 768px) {
  .queue-info {
    background: rgba(232, 232, 232, 0.9);
    padding: 5px;
    margin-top: 5px;
    border: 1px solid #fff;
  }
}
