.nav-container {
  height: 100px;
  width: 100%;
  background-color: #fff;
  position: fixed;
  transition: background-color .5s;
  border-bottom: 1px solid #ebebeb;
  z-index: 1;
}


.navActive {
  background-color: #f2f2f2;
  transition: background-color .5s;
  z-index: 10;
  border-bottom: 1px solid #ebebeb;
}

.nav-wrap {
  padding: 0 50px;
  display: flex;
  width: 100%;
  max-width: 1040px;
  height: 100%;
  margin: 0 auto;
}

.nav-logoWrap {
  width: 100%;
  max-width: 265px;
  display: flex;
  align-items: center;
}

.routes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 675px;
  height: 100%;
}

.sidebar {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 675px;
  height: 100%;
}

.item {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 13px;
  height: 20px;
  border-bottom: none;
}

.itemHome > a {
  color: #ffffff;
}

.item::after {
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  height: 1px;
  background: #ed6162;
  width: 100%;
  transform: translateY(-5px);
}

.active {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 13px;
  height: 20px;
  border-bottom: none;
  position: relative;
  color: #ed6162;
}

.active::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  height: 3px;
  background: #ed6162;
  width: 100%;
}

.item:hover {
  color: #ed6162;
  position: relative;

}

.item:hover::after {
  height: 3px;
  opacity: 1;
  transition: height .3s,opacity .3s,transform .3s,-webkit-transform .3s;
  transform: translateY(0px);
}


@media (max-width: 940px) {
  .routes {
    display: none;
  }
  .sidebar {
    display: flex;
  }
}