.done {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  text-align: center;
  opacity: 0;
  transform: translateY(12px) translateX(40px);
  color: #000;
  transition: all .6s;

  &.copied {
    opacity: 1;
    transform: translateX(50px) translateY(12px);
  }
}

.copy-to-clipboard-container {
  display: flex;
  align-items: center;
  padding-top: 15px;
  .copy-to-clipboard-title {
    padding-right: 10px;
  }
  justify-content: center;

  .copy-to-clipboard-btn {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: relative;
    transition: filter, box-shadow 0.2s linear;

    img {
      height: 26px
    }

    &:hover {
      filter: opacity(0.7);
      box-shadow: 0 0 0 1px #000;
    }
  }
}




