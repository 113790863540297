@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800&family=Roboto:wght@400;500;700;900&family=Source+Sans+Pro:wght@400;600;700;900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, Raleway, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  border: none;
  background: none;
  font-family: inherit;
  cursor: pointer;
}

* {
  box-sizing: border-box;
}