.container {
  height: 100%;
  min-height: calc(100vh - 56px);
  background: url("../../../../assets/images/bg.jpg");
  background-size: cover;
}

.loader {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
}

.wrap {
  padding: 172px 50px 20px;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
}

.content {
  background-color: #fff;
  border: 1px solid #fff;
  min-height: 150px;
  padding: 20px 10px 10px;
  border-radius: 10px;
  margin: 20px auto;

  p {
    white-space: pre-line;
  }
}

.teacher-toolbar {
  background-color: #c3c3c3;
  border: 1px solid #000;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & > * {
    flex: 1 1 365px;
  }
}

.first-row-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 25px;
}

.actions-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.title {
  font-family: Raleway, sans-serif;
  font-size: 30px;
  line-height: 34px;
  letter-spacing: 1px;
  font-weight: 700;
  color: #323436;
  text-transform: uppercase;
  font-style: italic;

  .class-code {
    text-transform: initial;
  }
}

.toolbar-actions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 15px;
}

.button {
  background-color: #3498db;
  font-family: Raleway, sans-serif;
  cursor: pointer;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  font-weight: 500;
  font-size: 22px;
  padding: 10px 20px;
  transition: background-color .3s;
  border-radius: 8px;
}

.button:hover {
  background-color: #3cb0fd;
  transition: background-color .3s;
}

.show-comments-container, .play-sounds-container {
  display: flex;
  align-items: center;

  & p {
    font-family: Raleway, sans-serif;
    font-size: 17px;
    line-height: 26px;
    font-weight: 500;
    padding-left: 8px;
    margin: 0;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 17px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  border: 2px solid #000;
  border-radius: 10px;
  transition: .3s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 10px;
  bottom: -1px;
  border: 2px solid #000;
  border-radius: 50%;
  background-color: #c3c3c3;
  transition: .3s;
}

input:checked + .slider {
  background-color: #c3c3c3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(-11px);
}

.buttonWrap {
  display: flex;
  align-items: center;
}

.end-session-container {
  position: relative;
  display: flex;
  align-items: center;
}

.clear-container {
  position: relative;
  display: flex;
  align-items: center;
}

.end-icon {
  width: 18px;
  height: 18px;
  position: absolute;
  left: 21px;
  top: 11px;
}

.clear-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 18px;
  top: 8px;
}

.classroom-students {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  max-height: 40vh;

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ed6162;
    border-radius: 300px;
  }

  ::-webkit-scrollbar-track {
    background-color: #e4e4e4;
  }

  @media (max-width: 620px) {
    flex-direction: column-reverse;
    max-height: unset;
  }
}

.messages {
  position: relative;
  display: flex;

  @media (max-width: 620px) {
    width: 100% !important;
  }
}

.students {
  width: 40%;
}

.toggle-participants {
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid gray;
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  background-color: rgba(238, 238, 238, .41);

  @media (max-width: 620px) {
    display: none
  }
}

.arrow-icon {
  width: 15px;
  height: 15px;
  margin-top: 6px;
}

.arrow-icon-rotate {
  transform: rotate(180deg) !important;;
}

.message-icon {
  width: 27px;
  height: 27px;
  cursor: pointer;
  margin-left: 10px;
  transition: transform .2s;

  &:hover {
    transform: scale(1.2);
  }
}

.messages-text {
  height: max-content;
  margin: 0;
  font-family: Raleway, sans-serif;
  font-size: 25px;
  line-height: 26px;
  font-weight: 500;
}

.message-sender {
  margin: 0;
  word-break: break-all;
  min-width: 150px;

  &:hover {
    text-decoration: line-through;
    cursor: pointer;
  }
}

.comment {
  margin: 0 10px;
  word-break: break-word;
  min-width: 60px;
}

.wrapBtnsGroup {
  padding: 0 10px;
}

.welcomeButton {
  background-color: #5cb85c;
  font-family: Raleway, sans-serif;
  cursor: pointer;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  padding: 10px 20px;
  transition: background-color .3s;
}

.welcomeButton:hover {
  background-color: #46a146;
  transition: background-color .3s;
}

.yesButton {
  background-color: #3498db;
  font-family: Raleway, sans-serif;
  cursor: pointer;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  transition: background-color .3s;
}

.yesButton:hover {
  background-color: #3cb0fd;
  transition: background-color .3s;
}

.noButton {
  background-color: #ed6162;
  font-family: Raleway, sans-serif;
  cursor: pointer;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  padding: 10px;
  transition: background-color .3s;
}

.noButton:hover {
  background-color: #e83637;
  transition: background-color .3s;
}

.backButton {
  background-color: #c3c3c3;
  font-family: Raleway, sans-serif;
  cursor: pointer;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  transition: background-color .3s;
}

.backButton:hover {
  background-color: #939393;
  transition: background-color .3s;
}

.start-session-wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 10px 20px
}

.start-session-btn-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 500px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  justify-content: space-evenly;
  align-items: center;
  background: #fff;

  > :nth-child(3) {
    padding: 20px 0 20px;
  }

  .start-session-title {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0;
    font-weight: 500;

    span {
      text-decoration: underline
    }
  }
}

.membership-select-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 68%
}

.assistance-requests-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 10px;
  margin: 0 40px 0 0;
  overflow-y: auto;

  .message-item:last-child {
    box-shadow: unset;
  }

  @media (max-width: 620px) {
    margin: 10px 0 0;
    max-height: 20vh;
  }
}

.assistance-request-container {
  display: flex;
}

.session-participants-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-height: 390px;


  & ol {
    flex: 1;
    overflow-y: auto;
    padding: 0 10px;
    scrollbar-gutter: stable;
    list-style: inside decimal;
  }

  & li {
    font-size: 25px;
    padding-bottom: 10px;
  }

  & > h3, ol {
    margin: 0;
  }

  @media (max-width: 620px) {
    max-height: 190px;
  }
}

.message-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0 10px;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 2px -2px #d7d7d7;
  -moz-box-shadow: 0 3px 2px -2px #d7d7d7;
  box-shadow: 0 3px 2px -2px #d7d7d7;

  div, p {
    flex: 1
  }

  @media (max-width: 620px) {
    flex-wrap: wrap
  }
}

.session-participants-title {
  text-decoration: underline;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0;
  font-weight: 500;
}

@media (max-width: 913px) {
  .buttons-container {
    padding-top: 15px;
    justify-content: center;
  }

  .title {
    text-align: center;
  }

  .toolbar-actions {
    justify-content: center;
  }

  .buttonWrap {
    padding-left: 30px;
  }

  .play-sounds-container {
    padding-left: 38px;
  }

  .show-comments-container {
    padding-left: 68px;
  }

  .toolbar-actions {
    padding-top: 20px;
  }
}

@media (max-width: 577px) {
  .buttonWrap {
    padding-top: 15px;
  }

  .toolbar-actions {
    flex-direction: column;
    align-items: center;
  }

  .show-comments-container {
    padding-left: 38px;
  }
  .play-sounds-container {
    padding-left: unset;
  }
}

.warning-container {
  border: 1px solid #000;
  background-color: rgba(255, 242, 0, 0.32);
  padding: 10px;
  text-align: center;
  display: flex;
  white-space: pre-line;

  a {
    color: #2980b9;

    &:hover {
      color: #ed6162;
    }
  }
}

.warning-img-container {
  padding-right: 5px;
}

@media (max-width: 450px) {
  .wrap {
    padding: 135px 20px 0;
  }
}