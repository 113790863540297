.warning-container {
  border: 1px solid #000;
  background-color: rgba(255, 242, 0, 0.32);
  padding: 10px;
  text-align: center;
  display: flex;
  white-space: pre-line;

  a {
    color: #2980b9;

    &:hover {
      color: #ed6162;
    }
  }
}

.warning-img-container {
  padding-right: 5px;
}
